import TYSHero from '../components/tys-parallax-hero';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import Popup from '../components/Popup';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function TheYoungSamaritan() {
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <TYSHero key={location.key} />
      <div className='tys-book-page-wrapper'>
        <div className='container-large' id='scroll'>
          <div className='get-a-copy'>
            <div className='title'>
              <h2>Get the Book</h2>
            </div>
            <div className='gac-grid'>
              <div
                className='book-cover'
                onMouseEnter={() => setIsHovered(true)}
                onClick={() => setIsHovered(false)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Popup
                  trigger={
                    <img
                      src={process.env.PUBLIC_URL + '/images/book1.png'}
                      alt='The Young Samaritan Book Cover'
                      className='clickable-image book'
                    />
                  }
                >
                  <img
                    src={process.env.PUBLIC_URL + '/images/tys-full-cover.jpg'}
                    alt='The Young Samaritan Full Book Cover'
                  />
                </Popup>
                {isHovered && <div className='hover-text'>View Full Cover</div>}
              </div>
              <div className='book-info'>
                <div className='book-title'>
                  <h4 className='title sans-serif'>The Young Samaritan</h4>
                  <p>by J. Schuyler Sprowles</p>
                </div>
                <p className='book-mini-sum'>
                  Joshua, a Samaritan teenager in ancient Samaria, embarks on a
                  life-changing journey amidst Jesus' early followers. Joshua
                  faces adversities and discoveries that shape his path to
                  maturity and faith. Set in historical biblical times, the
                  story vividly portrays Jesus' impact through Joshua's eyes,
                  appealing to both young and adult readers, believers and
                  non-believers alike.
                </p>
                <div className='purchase-buttons'>
                  <a href='https://a.co/d/dX1kChy' className='btn'>
                    <img
                      className='icon'
                      src={process.env.PUBLIC_URL + '/images/amazon.svg'}
                      alt=''
                    />
                    <span> Amazon</span>
                  </a>
                  <a
                    href='https://store.bookbaby.com/book/the-young-samaritan'
                    className='btn'
                  >
                    <img
                      className='icon'
                      src={process.env.PUBLIC_URL + '/images/bookbaby.svg'}
                      alt=''
                    />
                    <span> BookBaby</span>
                  </a>
                </div>
                <div className='publish-info'>
                  <p>
                    <strong>Publisher: </strong>BookBaby
                  </p>
                  <p>
                    <strong>Publish Date: </strong>February 16, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='title'>
            <h2>What people are saying</h2>
          </div>
          <div className='grid quotes'>
            <div className='grid-item'>
              <div></div> {/*   image gets injected here   */}
            </div>
            <div className='grid-item'>
              <p className='quote animate-text'>
                <span className='big-quote'>“</span>In this inspiring journey
                across the ancient landscapes of the gospels, Schuyler tells the
                untold tale of a resilient boy who, when others faltered, stood
                steadfastly by Jesus, showcasing the unwavering power of
                youthful courage, unyielding belief, and the enduring bond that
                transcends time.”
              </p>
              <p className='quote-title'>
                MATHEW P. JOHN
                <span>
                  <br />
                  Senior Pastor, Lake Avenue Church, Pasadena, CA
                </span>
              </p>
            </div>
            <div className='grid-item'>
              <p className='quote animate-text'>
                <span className='big-quote'>“</span>Get ready to encounter the
                people and places of the Ancient Near East through the eyes of
                Joshua, whose story will make you laugh, cry, and reflect on
                your own journey to love and be loved. Readers of all ages will
                be charmed as Sprowles draws upon the Gospel of Mark for the
                backdrop of this heartwarming coming-of-age tale.”
              </p>
              <p className='quote-title'>
                LISA HALLER LIOU
                <span>
                  <br />
                  Former Regional Director, Inter Varsity Christian
                  Fellowship/USA
                </span>
              </p>
            </div>
            <div className='grid-item'>
              <div></div> {/*   image gets injected here   */}
            </div>
            <button className='btn'>
              <Link to='/writing/the-young-samaritan/reviews'>
                More Reviews
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TheYoungSamaritan;
