import React from 'react';

function TYSReviews() {
  return (
    <>
      <div className='reviews-cover-image-container'>
        <img
          className='reviews-cover-image'
          src={process.env.PUBLIC_URL + '/images/tys-covershot-1.png'}
          alt=''
        />
      </div>
      <div className='reviews container-medium'>
        <h2>Reviews and Media</h2>
        <div className='grid-reviews'>
          <div className='grid-item'>
            <img
              className='logo'
              src={process.env.PUBLIC_URL + '/images/reader-favorite-logo.webp'}
              alt=''
            />
            <div className='review-title'>
              <h5>Reader's Favorite Review</h5>
            </div>
            <p>
              “Joshua's journey is full of unexpected allies and challenges. The
              mysterious woman who shares a haunting vision, the faithful wild
              dog who stays by his side, and a reclusive uncle whose presence
              offers shelter and riddles all add intrigue and depth to the tale.
              As Joshua joins a band of travelers led by a man called Rabbi,
              believed by many to be the Messiah, his path takes on a profound
              spiritual dimension. The story beautifully weaves together hope,
              faith, and redemption themes, inviting readers to reflect on the
              meaning of transformation and divine purpose...”
            </p>
            <a
              href='https://readersfavorite.com/book-review/the-young-samaritan'
              class='review-popup-box'
            >
              <div>Reader's Favorite Review</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <img
              src={process.env.PUBLIC_URL + '/images/mid-review-logo.webp'}
              alt='midwest book review'
              className='logo midwest'
            />
            <div className='review-title'>
              <h5>The Midwest Book Review</h5>
            </div>
            <p>
              “An engaging and fascinating novel that will have enormous appeal
              to readers with an interest in stories set in the time of Jesus,
              “The Young Samaritan” by J. Schuyler Sprowles is unreservedly
              recommended as a prized pick for personal reading lists and
              community library Historical Fiction and Christian Fiction
              collections. A great read from start to finish!”
            </p>
            <a
              href='https://www.midwestbookreview.com/sbw/may_24.htm#rc'
              class='review-popup-box'
            >
              <div>The Midwest Book Review</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <img
              src={process.env.PUBLIC_URL + '/images/goodreads-logo.png'}
              alt=''
              className='logo'
            />
            <div className='review-title'>
              <h5>Goodreads Reviews</h5>
            </div>
            <p>
              "This coming-of-age adventure tells of Joshua, a Samaritan boy,
              who fears his life is in danger and is forced to flee home. In
              this extraordinary journey, Joshua endures a harsh and unexpected
              world as heartbreaking as it is joyful."
            </p>
            <div className='RatingStatistics RatingStatistics__interactive RatingStatistics__centerAlign'>
              <div
                className='RatingStatistics__column'
                aria-label='Average rating of 4.90 stars.'
                role='figure'
              >
                <span
                  aria-label='Rating 4.9 out of 5'
                  role='img'
                  className='RatingStars RatingStars__medium'
                >
                  <span className='baseClass RatingStar--medium'>
                    <svg viewBox='0 0 24 24' role='presentation'>
                      <path
                        className='RatingStar__fill'
                        d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'
                      />
                    </svg>
                  </span>
                  <span className='baseClass RatingStar--medium'>
                    <svg viewBox='0 0 24 24' role='presentation'>
                      <path
                        className='RatingStar__fill'
                        d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'
                      />
                    </svg>
                  </span>
                  <span className='baseClass RatingStar--medium'>
                    <svg viewBox='0 0 24 24' role='presentation'>
                      <path
                        className='RatingStar__fill'
                        d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'
                      />
                    </svg>
                  </span>
                  <span className='baseClass RatingStar--medium'>
                    <svg viewBox='0 0 24 24' role='presentation'>
                      <path
                        className='RatingStar__fill'
                        d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'
                      />
                    </svg>
                  </span>
                  <span className='baseClass RatingStar--medium'>
                    <svg viewBox='0 0 24 24' role='presentation'>
                      <defs>
                        <clipPath id='clip_RatingStar_undefined0.9000000000000004medium'>
                          <path d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'></path>
                        </clipPath>
                        <path
                          id='path_RatingStar_undefined0.9000000000000004medium'
                          d='M24 9.63469C24 9.35683 23.7747 9.13158 23.4969 9.13158H15.0892L12.477 1.34327C12.4269 1.19375 12.3095 1.0764 12.16 1.02625C11.8966 0.937894 11.6114 1.07983 11.523 1.34327L8.91088 9.13158H0.503157C0.33975 9.13158 0.186521 9.21094 0.0922364 9.3444C-0.0680877 9.57134 -0.0140806 9.88529 0.212865 10.0456L7.00408 14.8432L4.40172 22.6166C4.35092 22.7683 4.37534 22.9352 4.46749 23.066C4.6275 23.2932 4.94137 23.3476 5.16853 23.1876L12 18.3758L18.8317 23.183C18.9625 23.2751 19.1293 23.2994 19.281 23.2486C19.5445 23.1604 19.6865 22.8752 19.5983 22.6117L16.996 14.8432L23.7872 10.0456C23.9206 9.95133 24 9.7981 24 9.63469Z'
                        ></path>
                      </defs>
                      <use
                        clip-path='url(#clip_RatingStar_undefined0.9000000000000004medium)'
                        href='#path_RatingStar_undefined0.9000000000000004medium'
                        class='RatingStar__backgroundFill'
                      ></use>
                      <path
                        class='RatingStar__fill'
                        d='M18 17.8405V22.5978L12 18.3758L5.16853 23.1876C4.94137 23.3476 4.6275 23.2932 4.46749 23.066C4.37534 22.9352 4.35092 22.7683 4.40172 22.6166L7.00408 14.8432L0.212865 10.0456C-0.0140806 9.88529 -0.0680877 9.57134 0.0922364 9.3444C0.186521 9.21094 0.33975 9.13158 0.503157 9.13158H8.91088L11.523 1.34327C11.6114 1.07983 11.8966 0.937894 12.16 1.02625C12.3095 1.0764 12.4269 1.19375 12.477 1.34327L15.0892 9.13158H18V14.1339L16.996 14.8432L18 17.8405Z'
                      ></path>
                    </svg>
                  </span>
                </span>
                <div className='RatingStatistics__rating'>4.90</div>
              </div>
            </div>
            <a
              href='https://www.goodreads.com/book/show/204368454-the-young-samaritan?ac=1&from_search=true&qid=2fapwvhqqU&rank=1#CommunityReviews'
              class='review-popup-box'
            >
              <div>Goodreads Reviews</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>Book Viral</h5>
            </div>
            <p>
              “With a delicacy befitting the word of God, Sprowles crafts a
              fascinating and believable account from just one Bible verse… With
              close attention to scriptural accounts, he brings us Joshua, a
              young Samaritan, whose epic journey brings not only trials and
              tribulations but also inner peace and hope…”
            </p>
            <a
              href='https://bookviralreviews.com/book-reviews/religious-historical-fiction/'
              class='review-popup-box'
            >
              <div>Book Viral</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>Star 88.3 Interview</h5>
            </div>
            <img
              src={process.env.PUBLIC_URL + '/images/star883.webp'}
              alt='Star 88.3 Logo'
            />
            <a
              href='https://star883.com/2024/03/28/j/'
              class='review-popup-box'
            >
              <div>Star 88.3 Interview</div>
              <h3>Listen</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>
                Interview with <em>Bobby Gunther Walsh</em>
              </h5>
            </div>
            <img
              src='https://1000logos.net/wp-content/uploads/2021/05/iHeartRadio-logo.png'
              alt='iHeartRadio logo'
            />
            <a
              href='https://www.iheart.com/podcast/1053-gunther-rewind-22862162/episode/j-schuyler-sprowles-2-22-24-153185007/'
              class='review-popup-box'
            >
              <div>Bobby Gunther Walsh</div>
              <h3>Listen</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>Forward Reviews</h5>
            </div>
            <p>
              “A faithless boy encounters Jesus and opens himself up to new ways
              of being with others in the heartwarming religious novel <br />
              <em>The Young Samaritan.</em>”
            </p>
            <a
              href='http://www.forewordreviews.com/reviews/the-young-Samaritan/'
              class='review-popup-box'
            >
              <div>Forward Reviews</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>Blue Ink Reviews</h5>
            </div>
            <p>
              “By depicting the disciples and Jesus through the eyes of young
              Joshua, author J. Schuyler Sprowles provides a fascinating
              perspective on the Messiah's earthly life. Sprowles presents the
              hardships of finding food and water while traveling, the delight
              of listening to Jesus's parables around a fire, and the fear that
              is sparked in Joshua upon hearing rumors that people want to kill
              the man he's following.”
            </p>
            <a
              href='http://www.blueinkreview.com/book-reviews/the-young-Samaritan/'
              class='review-popup-box'
            >
              <div>Blue Ink Reviews</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
          <div className='grid-item'>
            <div className='review-title'>
              <h5>Red Headed Book Lover</h5>
            </div>
            <p>
              “The Young Samaritan is a captivating and thought-provoking
              Biblical tale that will have its readers turning the pages with
              haste into the early hours of the morning. As I began to read The
              Young Samaritan, I immediately knew that this was going to be an
              addictive read and now that I have finished the novel, I can
              happily write that the book is just this! It is a wonderful and
              poignant tale that captivated me with its heart-warming moments
              and phenomenal literature courtesy of author J. Schuyler
              Sprowles.”
            </p>
            <a
              href='https://redheadedbooklover.com/the-young-samaritan-j-schuyler-sprowles/'
              class='review-popup-box'
            >
              <div>Red Headed Book Lover</div>
              <h3>Read more</h3>
              <img
                src={process.env.PUBLIC_URL + '/images/external-link.svg'}
                alt='external link'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default TYSReviews;
